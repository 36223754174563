<template>
  <div class="login" id="login">
    <div class="logo-top">
      <div class="img">
        <router-link to="/">
          <img src="../assets/img/xinli_logo.png">
          <img src="../assets/img/logo.jpg">
        </router-link>
      </div>
      <div class="name">多模态儿童身心健康成长规划系统</div>
    </div>
    <div class="login-shade">
<!--      <div class="font">-->
<!--        <img src="../assets/img/img_notebook.png">-->
<!--      </div>-->
      <div class="login-box">
        <div class="label">
          <div class="item" :class="{active: identity == 'user'}" @click="identityfn('user')">成长规划</div>
           <div class="item" :class="{active: identity == 'unit'}" @click="identityfn('unit')">单位管理</div>
<!--           <div class="item" :class="{active: identity == 'doctor'}" @click="identityfn('doctor')">亲职沟通</div>-->
          
        </div>
        <form action="/api/login" method="post">
          <h1>用户登录</h1>
          <label>账号</label>
          <div class="input-box">
            <span class="icon-phone"></span>
            <input
              type="text"
              autocomplete="off"
              placeholder="请输入账号"
              v-model="form.username"
              @keyup.enter="loginSubmit"
            >
          </div>
          <label>密码</label>
          <div class="input-box">
            <span class="icon-lock"></span>
            <input
              type="password"
              placeholder="请输入密码"
              autocomplete="off"
              v-model="form.password"
              @keyup.enter="loginSubmit"
            >/>
          </div>
          <div class="button-box">
            <button type="button" @click="loginSubmit">登录</button>
          </div>
          <div class="to-home">
            <router-link class="left" to="/register">还没有帐号？立即注册</router-link>
            <router-link class="right" to="/">去首页&gt;&gt;</router-link>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      login: false,
      identity: "user",
      form: {
        username: "aaa",
        password: "123456"
      }
    };
  },
  mounted: function() {
    let div = document.getElementById("login");
    let h = document.documentElement.clientHeight || document.body.clientHeight;
    div.style.minHeight = h + "px";
  },
  methods: {
    loginSubmit: function() {
      if (!this.form.username) {
        this.$layer.msg("请输入手机号码！");
        return true;
      }
      /* let phone = /^((13|14|15|17|18)[0-9]{1}\d{8})$/
      if (!phone.test(this.form.username)) {
        this.$layer.msg('您输入的手机号码不正确，请重新输入！')
        return true
      } */
      if (!this.form.password) {
        this.$layer.msg("请输入密码！");
        return true;
      }
      /* let psw = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,21}$/
      if (!psw.test(this.form.password)) {
        this.$layer.msg('密码格式不正确，由6-21字母和数字组成，不能为纯数字和纯字母！')
        return true
      } */
      let params = this.form;
      let url = "";
      if (this.identity == "user") {
        url = "login/login";
      } else if(this.identity == "unit") {
        url = "login/bdminLogin";
      }else{
       url = "login/doctorLogin";
      }
      this.$post(url, params)
        .then(res => {
          this.$layer.msg(res.msg);
          if (res.code === 1) {
            if (this.identity == "user") {
              localStorage.setItem("token", res.data.token);
              let redirect = "/";
              if (this.$route.query.redirect) {
                redirect = this.$route.query.redirect;
              }
              setTimeout(() => {
                this.$router.push(redirect);
              }, 1500);
            } else {
              setTimeout(() => {
                window.location.href = res.url
              }, 1500);
            }
          }
        })
        .catch(response => {
          this.$layer.msg("网络异常，请稍后重试！");
        });
    },
    identityfn(str) {
      this.identity = str;
    }
  }
};
</script>

<style lang="less" scoped>
@import url("../assets/css/page/login");
</style>
